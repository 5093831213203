import React, { useState } from 'react';
import { TextField, InputAdornment, Button, CircularProgress } from '@mui/material';

const CustomTextField = ({ heightInRows = 1, onSend }) => {
  const [message, setMessage] = useState('');
  const [isLoading, setLoading] = useState(false); // State to track loading

  const handleClick = () => {
    if (onSend) {
      onSend(message, setLoading);
      setMessage('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && onSend) {
        event.preventDefault(); // Prevent the default action
        onSend(message, setLoading);
        setMessage('');
    }
  };

  return (
    <TextField
      multiline
      minRows={heightInRows}
      variant="outlined"
      placeholder="Type your message here..."
      fullWidth
      value={message}
      onChange={e => setMessage(e.target.value)}
      onKeyDown={handleKeyDown}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isLoading ? (
              <CircularProgress size={20} />
            ) : onSend && (
              <Button
                sx={{
                  color: 'primary.main',
                  padding: '10px',
                  minWidth: 'auto',
                }}
                onClick={handleClick}
                disabled={isLoading}
              >
                Send
              </Button>
            )}
          </InputAdornment>
        ),
        style: {
          borderRadius: '8px',
          backgroundColor: '#333',
          borderColor: 'white',
        },
      }}
    />
  );
};

export default CustomTextField;
