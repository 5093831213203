
import React from 'react';
import { Container, CssBaseline, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CustomTextField from './components/CustomTextField';
import { handleSend } from './functions/messageHandlers';


// Create a custom theme
const theme = createTheme({
  palette: {
    mode: 'dark', // Dark mode
    background: {
      default: '#333', // Dark grey background
    },
    primary: {
      main: '#fff', // Sets primary color to white
    },
  },
});

function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container
        maxWidth="sm"
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        <div style={{ marginTop: 'auto', marginBottom: 60 }}>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>Expertise</Typography>
          <CustomTextField heightInRows = '3' />
        </div>
        <div>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>Company</Typography>
          <CustomTextField onSend={handleSend} />
        </div>
      </Container>
    </ThemeProvider>
  );
}

export default App;