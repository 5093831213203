import axios from 'axios'; // Make sure to install axios if not already

export const handleSend = async (message, setLoading) => {
    if (message.trim()) { // Check if the message is not just empty spaces
      try {
        setLoading(true); // Start loading
        const response = await axios.post('http://localhost:8080/v1/use_cases', { message });
        console.log('Message sent:', response.data); // Logging the response
      } catch (error) {
        console.error('Failed to send message:', error);
      } finally {
        setLoading(false); // End loading regardless of the result
      }
    }
};
